import type { TranslationInterface } from "./translation.types";

export const ptLocale: TranslationInterface = {
  ok: "OK",
  yes: "Sim",
  no: "Não",
  agree: "Concordo",
  cancel: "Cancelar",
  "yes-exit": "Sim, sair",
  "yes-reset": "Sim, redefinir",
  "no-cancel": "Não, cancelar",
  "are-you-sure": "Você tem certeza?",
  recording: "Gravando...",
  "thank-you": "Obrigado",
  "sorry-try-again": "Desculpe, tente novamente",
  "error-occurred": "Ocorreu um erro",
  "please-try-again": "Por favor, tente novamente",
  "write-a-message": "Escreva uma mensagem...",
  "send-message": "Enviar mensagem",
  connected: "Conectado",
  connecting: "Conectando",
  reconnecting: "Reconectando",
  reconnected: "Reconectado",
  disconnecting: "Desconectando",
  disconnected: "Desconectado",
  error: "Erro",
  "persist-session": "Persistir sessão",
  settings: "Configurações",
  close: "Fechar",
  help: "Ajuda",
  chat: "Bate-papo",
  send: "Enviar",
  copy: "Copiar",
  copied: "Copiado",
  "sound-effects": "Efeitos sonoros",
  language: "Idioma",
  select: "Selecionar",
  agent: "Agente",
  user: "Usuário",
  bot: "Bot",
  "close-widget": "Fechar widget",
  "got-any-questions": "Tem alguma dúvida? Converse conosco!",
  "typical-response-time": "Geralmente respondemos em menos de 1 minuto",
  "session-closed-lead": "Seu problema foi resolvido!",
  "create-new-ticket": "Criar novo ticket",
  exit: "Sair",
  "reset-conversation": "Redefinir conversa",
  "new-conversation": "Nova conversa",
  "welcome-title": "Bem-vindo ao nosso chat de suporte",
  "welcome-description":
    "Estamos aqui para ajudar! Inicie uma conversa e responderemos o mais rápido possível.",
  "your-name": "Seu nome",
  "your-email": "Seu endereço de email",
  "start-chat": "Falar com o suporte",
  "starting-chat": "Conectando...",
  "hello-greeting": "Olá! Como podemos ajudar você hoje?",
  "i-need-more-help": "preciso de mais ajuda",
  "this-was-helpful": "Isso foi útil",
  optional: "Opcional",
  "no-conversations-yet": "Nenhuma conversa ainda",
};
