import type { TranslationInterface } from "./translation.types";

export const nlLocale: TranslationInterface = {
  ok: "OK",
  yes: "Ja",
  no: "Nee",
  agree: "Akkoord",
  cancel: "Annuleren",
  "yes-exit": "Ja, afsluiten",
  "yes-reset": "Ja, resetten",
  "no-cancel": "Nee, annuleren",
  "are-you-sure": "Weet u het zeker?",
  recording: "Opname...",
  "thank-you": "Dank u",
  "sorry-try-again": "Sorry, probeer het opnieuw",
  "error-occurred": "Er is een fout opgetreden",
  "please-try-again": "Probeer het opnieuw",
  "write-a-message": "Schrijf een bericht...",
  "send-message": "Bericht verzenden",
  connected: "Verbonden",
  connecting: "Verbinding maken",
  reconnecting: "Opnieuw verbinding maken",
  reconnected: "Opnieuw verbonden",
  disconnecting: "Verbinding verbreken",
  disconnected: "Verbinding verbroken",
  error: "Fout",
  "persist-session": "Sessie behouden",
  settings: "Instellingen",
  close: "Sluiten",
  help: "Help",
  chat: "Chat",
  send: "Verzenden",
  copy: "Kopiëren",
  copied: "Gekopieerd",
  "sound-effects": "Geluidseffecten",
  language: "Taal",
  select: "Selecteren",
  agent: "Agent",
  user: "Gebruiker",
  bot: "Bot",
  "close-widget": "Widget sluiten",
  "got-any-questions": "Heeft u vragen? Chat met ons!",
  "typical-response-time": "We reageren doorgaans binnen 1 minuut",
  "session-closed-lead": "Uw probleem is opgelost!",
  "create-new-ticket": "Nieuw ticket aanmaken",
  exit: "Afsluiten",
  "reset-conversation": "Gesprek resetten",
  "new-conversation": "Nieuw gesprek",
  "welcome-title": "Welkom bij onze supportchat",
  "welcome-description":
    "We zijn hier om te helpen! Begin een gesprek en we nemen zo snel mogelijk contact met u op.",
  "your-name": "Uw naam",
  "your-email": "Uw e-mailadres",
  "start-chat": "Praat met ondersteuning",
  "starting-chat": "Verbinding maken...",
  "hello-greeting": "Hallo! Hoe kunnen we u vandaag helpen?",
  "i-need-more-help": "Ik heb nog meer hulp nodig",
  "this-was-helpful": "Mijn vraag is opgelost",
  optional: "Optioneel",
  "no-conversations-yet": "Nog geen gesprekken",
};
